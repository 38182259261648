import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Icon, Link, Box, Text, Flex } from "@nulogy/components";
import { config } from "../../playgrounds/icon";
import iconList from "../../utils/iconList";
import { SLACK_LINK, CODE_WIDTH } from "../../components/CONSTANTS";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Icon`}</h1>
    <p>{`Icons can be used alongside text to help assist users in finding certain actions on a page.`}</p>
    <Playground {...config} mdxType="Playground" />
    <p>{`If your interface requires an icon not listed here, please post a message in the `}<Link fontSize="20px" href={SLACK_LINK} mdxType="Link">{`#design-ops`}</Link>{` slack channel.`}</p>
    <h2>{`Available Icons`}</h2>
    <p>{`Nulogy uses a selection of solid style `}<Link fontSize="20px" href="https://fonts.google.com/icons" mdxType="Link">{`Material Design`}</Link>{` icons.`}</p>
    <Flex flexWrap="wrap" width="100%" maxWidth={CODE_WIDTH} mx="auto" mdxType="Flex">
  {iconList.map(icon => <Flex width="33%" p="x1" mdxType="Flex">
      <Icon icon={icon} size="x3" pr="x1" mdxType="Icon" />
      <Text mdxType="Text">{icon}</Text>
    </Flex>)}
    </Flex>
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/iconic-button"
        }}>{`IconicButton`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      